import React from "react";
import { Outlet } from 'react-router-dom'
import _translate from "../Globals/translation";
import ReportRoutes from "./ReportNavigation";

const Reports = () => {
    return <div className="fill-space">
        <div className="center">
            <div className='reports'>
                <h1 className="header1 header-reports">{_translate("Reports")}</h1>
                <ReportRoutes/>
                <Outlet/>               
            </div>
        </div>
    </div>
}

export default Reports