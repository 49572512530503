import React, {useEffect, useState} from "react";
import _translate, { convertFloatSeperationToString, _getEventText } from "../Globals/translation"
import { API_URL } from "../Globals/globalVariables";


interface parameters {
    sessionTokenAuth: string;
    username: string;
    fullName: string;
    userID: string;
    accountType: "sub" | "main";
    maxAccounts: number | null;
    event: string|undefined;
}

const Dashboard: React.FC<parameters> = ({sessionTokenAuth, userID, fullName, username, accountType, event, maxAccounts}) => {
    const [budget, setBudget] = useState<String>("");
    const [prepaid, setPrepaid] = useState<Boolean>(true);
    // const [budgetAndCredits, setBudgetAndCredits] = useState<String>("");

    useEffect(() => {
        // set Title of the Page for SEO reasons
        document.title = "Berlin SMS - "  + _translate("Dashboard");
      }, []);

    // getting user informations    
    const get_buget = async () => {
        try {
            // request the current budget    
            let response = await fetch(API_URL+'/budget', {method:'GET', 
                headers: {'Authorization': 'Bearer ' + sessionTokenAuth.toString()}});
            if(!response.ok){
                // Failed to get Budget
                alert(_translate("Sorry something went wrong. The site was unable to request the current Budget."));
            } else {
                // log budget
                let data = await response.json();
                // setBudgetAndCredits(data.budgetAndCredits.toString());
                setBudget(data.budget.toString());
            }
        } catch (error) {
            alert(_translate("Sorry something went wrong. The site was unable to request the current Budget."));
        }
    } 

    const get_UserData = async () => { 
        // request the current user data
        try {
            let response = await fetch(API_URL+'/currentuser', {method:'GET', 
                headers: {'Authorization': 'Bearer ' + sessionTokenAuth.toString()}});
            if(!response.ok){
                // Failed to get User Data
                alert(_translate("Sorry something went wrong. The site was unable to request the current User Data."));
            } else {
                // log user data
                let data = await response.json();
                setPrepaid(data.user.prepaid);
            }
        } catch (error) {
            alert(_translate("Sorry something went wrong. The site was unable to request the current User Data."));
        }
    }

    useEffect(() => {
        get_buget();
        get_UserData();
    }, []);

    const setBudgetText = () => {
        if(budget !== "" && Number(budget) >= 0) {
            return _translate("Your Budget is") + " " + convertFloatSeperationToString(budget.toString()).toString() + "€.";
        } else if(budget !== "" && Number(budget) < 0) {
            // return _translate("You have to pay") + " " + convertFloatSeperationToString(budgetAndCredits.toString()).toString() + "€.";
            if (prepaid === false) {
                return _translate("Current invoice amount") + " " + convertFloatSeperationToString(Math.abs(Number(budget)).toString()).toString() + "€.";
            } else {
                return _translate("You have to pay") + " " + convertFloatSeperationToString(Math.abs(Number(budget)).toString()).toString() + "€.";
            }
        } else {
            return _translate("Budget could not be loaded.");
        }
    }


    return <div className="fill-space">
         <div className="center">
            <div className='content-container dashboard'>
                <h1 className="header1 header-dashboard">{_translate("Dashboard")}</h1>
                { /* if there is a special event this block will be added */ event !== undefined ? 
                    <div className="special-event-container">
                        <h2 className="header2 header-login special-event-header">{_translate(_getEventText(event, "dashboard", "header"))}</h2>
                        <span className="textspan special-event-textspan">{_translate(_getEventText(event, "dashboard", "textspan"))}</span>
                    </div> : null
                }
                <div className="default-dashboard-container">
                    <span className="textspan textspan-greeting">{_translate("Hello")} { fullName.replace(" ","") === ""? username : fullName }!</span>

                    {/* AccountType sub does not own Budget */}
                    {accountType === "main" ?
                        <><span className="textspan textspan-budget">{setBudgetText()}</span>
                        <span className="textspan textspan-additionalcontent"></span></>
                        : <></>}
                </div>
                <div className="tutorial-dashboard-container">
                    <h2 className="header2 tutorial-header">{_translate("Check out all of our features, by using the sidebar.")}</h2>
                    <div className="step-container step-container-1">
                        <h3 className="header3 tutorial-step tutorial-step-1" onClick={() => window.location.assign("/dashboard")}>{_translate("Dashboard")}</h3>
                        <span className="textspan textspan-tutorial-steps textspan-tutorial-step-1">{_translate("Using the Dashboard tab you get back to this page.")}</span>
                        <h3 className="header3 tutorial-step tutorial-step-2" onClick={() => window.location.assign("/profile")}>{_translate("My Profile")}</h3>
                        <span className="textspan textspan-tutorial-steps textspan-tutorial-step-2">{_translate("The Profile tab shows you all your Profile Data aswell with your API Key and additional information.")}</span>                        
                        <h3 className="header3 tutorial-step tutorial-step-3"  onClick={() => window.location.assign("/sendsms")}>{_translate("Send SMS")}</h3>
                        <span className="textspan textspan-tutorial-steps textspan-tutorial-step-3">{_translate("Here you can try out and test our SMS sending interface.")}</span>
                        <h3 className="header3 tutorial-step tutorial-step-4"  onClick={() => window.location.assign("/Reports")}>{_translate("Reports")}</h3>
                        <span className="textspan textspan-tutorial-steps textspan-tutorial-step4">{_translate("On the Reports tab you can show a complete table of your sms send statistics.")}</span>
                        {/* AccountType sub does not have twofa, invoices nor balance */}
                        {accountType === "main" ? <>
                            <h3 className="header3 tutorial-step tutorial-step-4"  onClick={() => window.location.assign("/invoices")}>{_translate("Invoices")}</h3>
                            <span className="textspan textspan-tutorial-steps textspan-tutorial-step-4">{_translate("List all your invoices in a table and download them by clicking on a table row.")}</span>
                            <h3 className="header3 tutorial-step tutorial-step-5"  onClick={() => window.location.assign("/balance")}>{_translate("Balance")}</h3>
                            <span className="textspan textspan-tutorial-steps textspan-tutorial-step-5">{_translate("Recharge your balance by generating a invoice.")}</span>
                            {maxAccounts !== null && maxAccounts > 0 ? <><h3 className="header3 tutorial-step tutorial-step-6"  onClick={() => window.location.assign("/account-manager")}>{_translate("Account Manager")}</h3>
                            <span className="textspan textspan-tutorial-steps textspan-tutorial-step-6">{_translate("By using the account manager you can manage your sub accounts. You can create, edit and delete sub accounts. Also it is possible to easily switch to a sub account.")}</span></> : <></>}                            
                            <h3 className="header3 tutorial-step tutorial-step-7"  onClick={() => window.location.assign("/keygen")}>{_translate("2FA")}</h3>
                            <span className="textspan textspan-tutorial-steps textspan-tutorial-step-7">{_translate("Start using our Two Factor Authentification. Here you can setup all the parameters to use our Two Factor Authentification Component.")}</span>
                        </> : <></>}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Dashboard

