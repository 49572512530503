// ReCAPTCHA - not needded
export const REACT_APP_SITE_KEY_ReCAPTCHA = "6Lc4zdEiAAAAAP0KzpBp8ku0YSX4slR1GaH7oSeu"
// HCAPTCHA- not needded
export const REACT_APP_SITE_KEY_HCAPTCHA_old = "9a723b69-b2fc-4d49-993a-eb05254f0c5d"
export const REACT_APP_SITE_KEY_HCAPTCHA = "ff77c0f9-f527-4df2-9a3a-1c4be0db7e9c"
//BerlinSMS
export const REACT_APP_SITE_KEY_BERLINSMS = "iEEv9ICzbUCzVbywMdAHMGuTtrlmcj7TUGkmvZZXPgfEVCZSYTafWeCgFkUeeCsJ8upnY04KQjryDIZreJBz8yYIhHkzeorG43qX"
//ROUTES
export const API_URL="https://api.berlinsms.de"
// PAYPAL
// export const PAYPALCLIENTID = "AQWvhLsVjv57jQLGiB_oAq4yMCblG0pwI2zrRP5hBjiAMTbIH4MLaU9-G6vjat3x1qTh6FYu6037keIY" // -- dev
export const PAYPALCLIENTID = "AcQY0X-rKJpgUCXoOjilNj3kAPPVxOnkxTwdB_OBvJ9VmGxLvbyyaccExYytZrIvdweMIeJrV7gJhkBr" // -- live
export interface currentAccountInfo {
    userId: string,
    accountType: "sub" | "main",
    tag: string,
    apikey: string,
    userName: string,
    fullName: string,
    phoneNumber: string,
    recharge_amount: number,
    maxAccounts: number,
    lang: "de" | "en",
    prepaid: boolean,
    taxRate: number,
    manuallyVerified: boolean
    sessionTokenAuth: string
}
export const currentAccountInfoDefault: currentAccountInfo = {
    userId: "",
    accountType: "main",
    tag: "",
    apikey: "",
    userName: "",
    fullName: "",
    phoneNumber: "",
    recharge_amount: 0,
    maxAccounts: 0,
    lang: "de",
    prepaid: false,
    taxRate: 0,
    manuallyVerified: false,
    sessionTokenAuth: ""
}
