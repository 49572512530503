import _translate from "../Globals/translation"

const text_create = _translate("After they press the button, a form appears. In this form you need to enter your data, such as domains or captcha keys. After you save the page, the BerlinSMS TwoFA Keys will be automatically generated and displayed in the form.");
const text_label = _translate("The website label is the name of your configuration. This name is necessary to distinguish between different configurations.");
const text_TwoFType = _translate("Use the TwoFA type to select the verification method you want to add to your website. Currently supported are Optin(SMS) and Optin(Mail).");
const text_Captcha = _translate("You have to register the captcha keys independently via the respective provider. When registering a captcha, you will receive two keys. Once a sitekey and a secretkey. In order for our TwoFA component to protect you from automated requests, it is necessary to provide us with these two keys. Currently the captcha types ReCaptcha and HCaptcha are supported.");
const text_TwoFAKeys = _translate("After you have filled in all fields (except this one), you can save your configuration. Afterwards the TwoFA Keys will be generated and displayed at this point. You can then use these to integrate our component on your website.");
const text_Domains = _translate("Domains are the actual web addresses under which your component will run. For each request, the system checks whether the requesting domain corresponds to one of the stored domains. A distinction must also be made here between subdomains (e.g. app.berlinsms.de, twofa.berlinsms.de, ...). For local testing, domains such as localhost also work.");
const text_TwoFAKeys_2 = _translate("After saving your configuration, you can integrate the 2FA component on your website using the two-factor authentication key shown here. Always keep the secret key to yourself and only enter the website key on your website.")

const tutorial = {

    text_create: text_create,
    text_label: text_label,
    text_TwoFType: text_TwoFType,
    text_Captcha: text_Captcha,
    text_TwoFAKeys: text_TwoFAKeys,
    text_Domains: text_Domains,
    text_TwoFAKeys_2: text_TwoFAKeys_2,
}

export default tutorial