// import { t } from "react";
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { PAYPALCLIENTID } from "../Globals/globalVariables";

const initialOptions = {
    "client-id": PAYPALCLIENTID,
    currency: "EUR",
    locale: "de_DE",
    // "fundingSource": "paypal",
    // style: {layout: "horizontal"},
    // className: "Button PayPalButtons PayPalButton", 
};

interface PayPalOrderParameter {
    currency: string;
    netto: Number;
    brutto: Number;
    taxes: Number;
    additional_fees: Number;
    campaign: string;
    onApprovedCallback: (details: any) => void;
}
interface DetailedPayPalOrderParameter {
    currency: string;
    netto: Number;
    brutto: Number;
    taxes: Number;
    additional_fees: Number;
    campaign: string;
    onApprovedCallback: (details: any) => void;
}

const PayPalButtonWrapper:React.FC<PayPalOrderParameter> = ({currency, netto, brutto, taxes, additional_fees, campaign, onApprovedCallback}) => {

    return (
        <PayPalScriptProvider options={initialOptions}>
            <PayPalButton
                currency={currency}
                netto={netto}
                brutto={brutto}
                taxes={taxes}
                additional_fees={additional_fees}
                campaign={campaign}
                onApprovedCallback={onApprovedCallback} 
            />
        </PayPalScriptProvider>
    );
}
const PayPalButton: React.FC<DetailedPayPalOrderParameter> = ({currency, netto, brutto, taxes, additional_fees, campaign, onApprovedCallback }) => {
    const [{isPending }] = usePayPalScriptReducer();
    return (<>  
        { (isPending) && <div className="spinner" /> }
        <PayPalButtons  
            fundingSource="paypal"
            style={{layout: "horizontal"}}
            className="Button PayPalButtons PayPalButton"
            forceReRender={[brutto, currency]}
            createOrder={(data, actions) => {
                return actions.order.create({
                    purchase_units: [
                        {
                            amount: {
                                currency_code: currency,
                                value: brutto.toFixed(2),
                                breakdown: {
                                    item_total: {
                                        currency_code: currency,
                                        value: brutto.toFixed(2),
                                    },
                                },
                            },
                            items: [
                                {
                                    name: " Berlinsms Guthaben " + campaign,
                                    unit_amount: {
                                        currency_code: currency,
                                        value: netto.toFixed(2),
                                    },
                                    quantity: "1",
                                },{
                                    name: " Berlinsms PayPal Gebühr ",
                                    unit_amount: {
                                        currency_code: currency,
                                        value: additional_fees.toFixed(2),
                                    },
                                    quantity: "1",
                                },{
                                    name: " Mehrwertsteuer 19% ",
                                    unit_amount: {
                                        currency_code: currency,
                                        value: taxes.toFixed(2),
                                    },
                                    quantity: "1",
                                },

                            ],
                        },
                    ],
                });
            }}
            onApprove={(data, actions) => {
                return actions.order!.capture().then((details) => {
                    // Your code here after capture the order
                    onApprovedCallback(details);
                });
            }}                    
            onError={(err) => {
                // Your code here after capture the order
                console.log("onError called: " + err);
            }}
            onCancel={(data, actions) => {
                // Your code here after capture the order
                console.log("onCancel called: " + data, actions);
            }}
        />
    </>
    );
}
export default PayPalButtonWrapper;