interface LanguageType {
    English: String;
    German: String;
}

class translate {
    defaultLanguage: string = "Ger";

    setLanguage = (language: string) => {
        language==="Ger" || language==="Eng" ? this.defaultLanguage = language : this.defaultLanguage = "Eng";
    }

    LanguagesList: Array<LanguageType> = [
        /* TwoFA.tsx */
        {English: "Please enter your Phone number", German: "Bitte geben Sie Ihre Telefonnummer ein"},
        {English: "Please enter your E-Mail number", German: "Bitte geben Sie Ihre E-Mail Adresse ein"},
        {English: "Phonenumber", German: "Telefonnummer"},
        {English: "E-Mail", German: "E-Mail"},
        {English: "Verify", German: "verifizieren"},
        {English: "SMS send to:", German: "SMS gesendet an:"},
        {English: "E-Mail send to:", German: "E-Mail gesendet an:"},
        {English: "Check code", German: "Code überprüfen"},
        {English: "If you have not received the code, you can request a new one.", German: "Wenn Sie den Code nicht erhalten haben, können Sie einen neuen anfordern."},
        {English: "Request new code", German: "Neuen Code anfordern"},
        {English: "Loading...", German: "Lädt..."},
        {English: " verified.", German: " verifiziert."},
        {English: "Login verified.", German: "Login verifiziert."},
        {English: "validate", German: "überprüfen"},
        {English: "Try again", German: "Erneut versuchen"},

        {English: "We have send an Security Code to:", German: "Wir haben einen Sicherheitscode gesendet an:"},

        /* TwoFA Errormessages */
        {English: "Error: ", German: "Fehler: "},
        {English: "Failed to send code. Please try again later.", German: "Code konnte nicht gesendet werden. Bitte versuchen Sie es später erneut."},
        {English: "Please enter a valid code! (6 digits)", German: "Bitte geben Sie einen gültigen Code ein! (6 Ziffern)"},
        {English: "Error: Failed to validate the Code.", German: "Fehler: Code konnte nicht verifiziert werden."},
        {English: "Failed to validate the Code. Error: No more tries.", German: "Code konnte nicht verifiziert werden. Fehler: Keine Versuche mehr."},
        {English: "Wrong code! You have ", German: "Falscher Code! Sie haben noch "},
        {English: " tries left.", German: " Versuche übrig."},
        {English: "1 try left.", German: "1 Versuch übrig."},
        {English: "Failed to validate the Code. \nForm was not correctly filled or the number of attempts ran out.", German: "Code konnte nicht verifiziert werden. Formular nicht korrekt ausgefüllt oder keine Versuche übrig."},
        {English: "Failed to validate the Code. Error: challengeToken not found", German: "Code konnte nicht verifiziert werden. Fehler: challengeToken nicht gefunden"},
        {English: "API not available!", German: "API nicht verfügbar!"},
        {English: "Error: current site does not match expected domains.", German: "Fehler: Aktuelle Seite stimmt nicht mit erwarteten Domains überein."},
        {English: "Error: Failed to Load API-validation response.", German: "Fehler: API-Validierungsantwort konnte nicht geladen werden."},
        {English: "Error: Captcha Token is null or undefined.", German: "Fehler: Captcha Token ist null oder undefiniert."},
        {English: "Error occurred please contact your website admin. Error: ", German: "Bitte kontaktieren Sie Ihren Website Admin. Fehler: "},
        {English: "Please enter a valid phone number!", German: "Bitte geben Sie eine gültige Telefonnummer ein!"},
        {English: "Failed to send code.", German: "Code konnte nicht gesendet werden."},
        {English: "Failed to validate the Code.", German: "Code konnte nicht verifiziert werden."},
        {English: "Failed to start session.", German: "Sitzung konnte nicht gestartet werden."},
        {English: "BerlinSMS Sitekey does not match your current domain!", German: "BerlinSMS Sitekey stimmt nicht mit Ihrer aktuellen Domain überein!"},
        {English: "API responded not as expected!", German: "API hat nicht wie erwartet reagiert!"},
        {English: "Failed to send code. - Most likely the Form was not correctly filled or the number of attempts ran out.", German: "Code konnte nicht gesendet werden. - Wahrscheinlich wurde das Formular nicht korrekt ausgefüllt oder die Anzahl der Versuche ist abgelaufen."},
        {English: "Failed to validate the Code. Error: challengeToken not found.", German: "Code konnte nicht verifiziert werden. Fehler: challengeToken nicht gefunden."},
        {English: "Failed to start session.", German: "Sitzung konnte nicht gestartet werden."},	        
    ]

    translate = (text: string) => {
        if(this.defaultLanguage==="Ger"){
            for(var searchvar = 0; searchvar < this.LanguagesList.length; searchvar++){
                if(this.LanguagesList[searchvar].English === text) return this.LanguagesList[searchvar].German;
            }
        }
        return text;
    }
}   

export default translate;
