export const API_URL = "https://api.berlinsms.de";
// CHANGE FOR LIVE
export interface TwoFAParameters {
    bsmsSitekey: string;
    bsmsLanguage?: "Eng" | "Ger";
    onVerify?: ((token: string, code?: string) => any) | undefined;
    onExpire?: (() => any) | undefined;
    onError?: ((error: string) => any) | undefined;
};
export type captchaTypes = "reCaptcha"| "hCaptcha";
export type verificationTypes = "optin(sms)" | "optin(mail)" | "optin(mail+sms)" | "sms-auth" | "mail-auth" | "oauth2";
export type renderStatusTypes = "captcha" | "initialMethod" | "methods" | "codeInput" | "verified" | "loading" | "error";

export interface TwoFAState {
    // real status codes
    verificationType: verificationTypes;
    apiReady: boolean;
    codeSend: boolean;
    codeVerified: boolean;
    errorOccured: boolean;
    errorMessage: string;
    // internal status codes
    renderStatus: renderStatusTypes;
    captchaSitekey: string;
    captchaType: captchaTypes;
    captchaToken: string;
    validationCode: string;
    challengeToken: string;
    // internal variables
    limitAttempts: number;
    verificationAddress: string;
};
export const defaultTwoFAState: TwoFAState = {
    // real status codes
    verificationType: "optin(sms)",
    apiReady: false,
    codeSend: false,
    codeVerified: false,
    errorOccured: false,
    errorMessage: "",
    // internal status codes
    renderStatus: "loading",
    captchaSitekey: "",
    captchaType: "reCaptcha",
    validationCode: "",
    challengeToken: "",   
    captchaToken: "", 
    // internal variables
    limitAttempts: Infinity,
    verificationAddress: "",
};
