import React from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import ReCAPTCHA from "react-google-recaptcha";

interface TwoFACaptchaLoaderParameters {
    captchaSitekey: string;
    captchaType: "reCaptcha" | "hCaptcha";
    onChange: (token: string | null | undefined) => void;
    onExpire: () => void;
    onError: (error: string) => void;
};

class TwoFACaptchaLoader extends React.Component<TwoFACaptchaLoaderParameters> {
    constructor(props: TwoFACaptchaLoaderParameters) {
        super(props);
        // binding the functions to the class
        this.reset = this.reset.bind(this);
        this.getReCaptchaRef = this.getReCaptchaRef.bind(this);
        this.getHCaptchaRef = this.getHCaptchaRef.bind(this);
    }

    // HCaptcha functions
    private captchaRef_HCAPTCHA = React.createRef<HCaptcha>();
    onVerify_HCapcha = (token: string) => {
        this.props.onChange(token);
    };
    onExpire_HCaptcha = () => {
        this.props.onExpire();
    };
    onError_HCaptcha = (error: string) => {
        this.props.onError(error);
    };
    // ReCapcha functions
    private captchaRef_ReCAPTCHA = React.createRef<ReCAPTCHA>();
    onExpired_ReCaptcha = () => {
        this.props.onExpire();
    }
    onChange_ReCaptcha = (value: string | null) => {
        const token = this.captchaRef_ReCAPTCHA?.current?.getValue();
        this.props.onChange(token);
    }
    onErrored_ReCaptcha = () => {
        this.props.onError("ReCaptcha - onErrored_ReCaptcha called.");
    }

    // global functions
    reset = () => {
        if(this.props.captchaType === "hCaptcha")
            this.captchaRef_HCAPTCHA.current?.resetCaptcha();
        else if (this.props.captchaType === "reCaptcha")
            this.captchaRef_ReCAPTCHA.current?.reset();
    }
    getReCaptchaRef = () => {
        return this.captchaRef_ReCAPTCHA;
    }
    getHCaptchaRef = () => {
        return this.captchaRef_HCAPTCHA;
    }

    render(): React.ReactNode  {
        return <div className="twofacomponent_container twofacomponent_container_captcha">
        { this.props.captchaType === "hCaptcha" ?
            <HCaptcha
                sitekey={this.props.captchaSitekey}
                onVerify={this.onVerify_HCapcha}
                onExpire={this.onExpire_HCaptcha}
                ref={this.captchaRef_HCAPTCHA}
                onError={this.onError_HCaptcha}
                />
        : this.props.captchaType === "reCaptcha" ?
            <ReCAPTCHA
                sitekey={this.props.captchaSitekey}
                onChange={this.onChange_ReCaptcha}
                ref={this.captchaRef_ReCAPTCHA}
                onExpired={this.onExpired_ReCaptcha}
                onErrored={this.onErrored_ReCaptcha}
                />
        :<></>}</div>
    }
}
export default TwoFACaptchaLoader;