import React from "react";
import translate from "./translation";
import { verificationTypes } from "./TwoFATypes";

interface TwoFACodeInputState {
    isCodeValid: boolean;
    validationCode: string;
};
interface TwoFACodeInputParameters {
    verificationType: verificationTypes;
    verificationAddress: string;
    triesLeft: number;
    _t: translate;
    onCodeSumbit: (code: string) => void;
    reset: () => void;
};
class TwoFACodeInput extends React.Component<TwoFACodeInputParameters, TwoFACodeInputState> {
    private codeInputRefs: React.RefObject<HTMLInputElement>[] = [];
    constructor(props: TwoFACodeInputParameters) {
        super(props);
        // binding the functions to the class
        this.state = {
            isCodeValid: false,
            validationCode: "",
        };
        for(let i = 0; i < 6; i++){
            this.codeInputRefs.push(React.createRef<HTMLInputElement>());
        }
    }

    private manualCodeSubmitNeeded: boolean = false;
    onCodeDigitInput = (event: React.FormEvent<HTMLInputElement>) => {
        var input = event.currentTarget;
        // check if the input is a number and prevent entering more than one number
        if (!(/^\d+$/.test(input.value) && (input.value.length === 1))) { 
            input.value = "";
            return;
        }
        // set state of the code by joining the code digits
        var _code = "";
        for(let i = 0; i < 6; i++){
            _code += this.codeInputRefs[i].current?.value;
        }
        this.setState({validationCode: _code});
        // check if the code is valid
        _code.length === 6 ? this.setState({isCodeValid: true}) : this.setState({isCodeValid: false});
        //focus the next input field
        const nextInput = input.nextSibling as HTMLInputElement;
        if (nextInput) {
            nextInput.focus(); 
        } else if(!this.manualCodeSubmitNeeded && _code.length === 6) {
            this.manualCodeSubmitNeeded = true;
            this.props.onCodeSumbit(_code);
        }
    }

    render (): React.ReactNode {
        return (
            <div className="twofacomponent_container twofacomponent_container_CodeValudation">
                {this.props.triesLeft !== Infinity ? 
                    <label className="descr-label TwoFAComponent_DescriptionSpan TwoFAComponent_Label_CodeDescription">
                        {this.props.triesLeft !== 1 ? this.props.triesLeft.toString() + this.props._t.translate(" tries left."): this.props._t.translate("1 try left.")}
                    </label> : 
                    <label className="descr-label TwoFAComponent_DescriptionSpan TwoFAComponent_Label_CodeDescription">
                        {this.props._t.translate("We have send an Security Code to:") + this.props.verificationAddress}
                    </label>}
                <input className="bsmstwofa-code-digit" ref={this.codeInputRefs[0]} onInput={this.onCodeDigitInput}></input>
                <input className="bsmstwofa-code-digit" ref={this.codeInputRefs[1]} onInput={this.onCodeDigitInput}></input>
                <input className="bsmstwofa-code-digit" ref={this.codeInputRefs[2]} onInput={this.onCodeDigitInput}></input>
                <input className="bsmstwofa-code-digit" ref={this.codeInputRefs[3]} onInput={this.onCodeDigitInput}></input>
                <input className="bsmstwofa-code-digit" ref={this.codeInputRefs[4]} onInput={this.onCodeDigitInput}></input>
                <input className="bsmstwofa-code-digit" ref={this.codeInputRefs[5]} onInput={this.onCodeDigitInput}></input>

            {/* <input className="input TwoFAComponent_Input TwoFAComponent_code_input" type="email"  onInput={this.onCodeInput} required placeholder="123456"></input> */}
            {this.state.isCodeValid ? <button className="button TwoFAComponent_Button TwoFAComponent_Button_checkCode" onClick={() => this.props.onCodeSumbit(this.state.validationCode)}>{this.props._t.translate("validate")}</button>: <></>}
            {this.state.isCodeValid ? <label className="descr-label TwoFAComponent_DescriptionSpan TwoFAComponent_Label_resetLabel">{this.props._t.translate("If you have not received the code, you can request a new one.")}</label>: <></>}
            {this.state.isCodeValid ? <button className="button TwoFAComponent_Button TwoFAComponent_Button_Reset" onClick={this.props.reset}>{this.props._t.translate("Request new code")}</button>: <></>}
        </div>);
    }
}
export default TwoFACodeInput;