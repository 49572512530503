import React, { useEffect, useState} from "react";
import _translate, {convertFloatSeperationToString} from "../Globals/translation";
import PayPalButtonWrapper from "./PayPalCheckout";
import { API_URL } from "../Globals/globalVariables";

interface SendingParameters {
    sessionTokenAuth: String,
    prepaid: boolean | null,
    taxRate: number | null,
    manuallyVerified: boolean | null,
}

const Balance: React.FC<SendingParameters> = ({sessionTokenAuth, prepaid, taxRate, manuallyVerified}) => {
    const [currentbudget, setCurrentBudget] = useState<string>("");
    // const [budgetAndCredits, setBudgetAndCredits] = useState<string>("");
    const [budgetToCharge, setBudgetToCharge] = useState<number>(0);
    const [campaign, setCampaign] = useState<string>("");

    useEffect(() => {
        // set Title of the Page for SEO reasons
        document.title = "Berlin SMS - "  + _translate("Balance");
      }, []);

    // get the current budget
    const get_buget = async () => {
        // request the current budget    
        try {
            let response = await fetch(API_URL+'/budget', {method:'GET', 
                headers: {'Authorization': 'Bearer ' + sessionTokenAuth.toString()}});
                if(!response.ok){
                // Failed to get Budget
                alert(_translate("Sorry something went wrong. The site was unable to request the current Budget."));
            } else {
                // log budget
                let data = await response.json();
                // setBudgetAndCredits(data.budgetAndCredits.toString());
                setCurrentBudget(data.budget.toString());
            }
        } catch (error) {
            alert(_translate("Sorry something went wrong. The site was unable to request the current Budget."));
        }
    } 

    const setBudgetText = () => {
        if(currentbudget !== "" && Number(currentbudget) >= 0) {
            return _translate("Your Budget is") + " " + convertFloatSeperationToString(currentbudget.toString()).toString() + "€.";
        } else if(currentbudget !== "" && Number(currentbudget) < 0) {
            if (prepaid !== undefined && prepaid === false) {
                return _translate("Current invoice amount") + " " + convertFloatSeperationToString(Math.abs(Number(currentbudget)).toString()).toString() + "€.";
            } else {            
                return _translate("You have to pay") + " " + convertFloatSeperationToString(Math.abs(Number(currentbudget)).toString()).toString() + "€.";
            }
        } else {
            return _translate("Budget could not be loaded.");
        }
    }

    
    // request a invoice for the current budget
    const requestInvoice = async (credits: number, currency: string = "", provider: string = "", orderId: string ="", campaign: string = "", invoiceAddress: {} = "") => {
        if(credits.valueOf() <= 0){
            alert(_translate("Please enter a valid amount!"));
            return;
        } else if(campaign.length > 20){
            alert(_translate("Please do not use more than 20 characters for your campaign!"));
            return;
        }

        // setting the url
        var url = API_URL + "/invoice"
        // request the invoice
        try {
            let response = await fetch(
                url, {method:'POST', 
                    headers: { 'Authorization': 'Bearer ' + sessionTokenAuth.toString(), 'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        campaign: campaign,
                        credits: credits,
                        currency: currency,
                        provider: provider,
                        orderId: orderId,
                        paypalAddress: invoiceAddress})});
            if(!response.ok){
                alert(_translate("Sorry, the service was unable to create a invoice for your current request. It might be that the campain you entered contains invalid characters. Please avoid using special characters like: /, \\, *, ?, !, #, $, %, &, (, ), [, ], {, }, <, >, |, +, =, ~, `, ^, @, ', \""));
            } else {
                let answer = await response.json();
                var invoiceArr = base64ToArrayBuffer(answer.pdf);
                saveByteArray(answer.filename, invoiceArr);
            }  
        } catch (error) {
            alert(_translate("Sorry, the service was unable to create a invoice for your current request. It might be that the campain you entered contains invalid characters. Please avoid using special characters like: /, \\, *, ?, !, #, $, %, &, (, ), [, ], {, }, <, >, |, +, =, ~, `, ^, @, ', \""));
        }
    } 
    // convert base64 to arraybuffer
    function base64ToArrayBuffer(base64: string) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
           var ascii = binaryString.charCodeAt(i);
           bytes[i] = ascii;
        }
        return bytes;
    }
    // save the invoice as pdf
    function saveByteArray(reportName: string, byte: any) {
        var blob = new Blob([byte], {type: "application/pdf"});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        link.click();
    };

    // callback function for the budget input field
    // validates the input and sets the budget to charge
    const budgetChecker = (e: React.FormEvent<HTMLInputElement>) => {
        let insertedBudget = Number(e.currentTarget.value);
        // check if the inserted budget is negative or empty
        if(insertedBudget.valueOf() < 0 || insertedBudget.toString() === ""){
            alert(_translate("Please enter a valid amount!"));
            setBudgetToCharge(0);
            return;
        } else {
            // round the budget to charge to 2 decimal places
            let _budget = Math.round(insertedBudget * 100) / 100;
            // set the budget to charge to the inserted budget
            setBudgetToCharge(_budget.valueOf());
        }
    }
    interface PayPalAddress {
        address_line_1: string,
        address_line_2: string,
        postal_code: string,
        admin_area_2: string,
        country_code: string,
    }

    // callback function for the paypal button
    const PayPalonApprovedCallback = (details: any) => {
        const PayPalAddressToString = (address: PayPalAddress) => {
            return {
                address_line_1: address.address_line_1,
                address_line_2: address.address_line_2,
                postal_code: address.postal_code,
                admin_area_2: address.admin_area_2,
                country_code: address.country_code,
            }
        }
        let address: PayPalAddress = details.purchase_units[0].shipping?.address;
        requestInvoice(budgetToCharge, "EUR", "paypal", details.id, campaign, PayPalAddressToString(address));
    };
    
    // add missing 0 to the end of the string if the string is not complete
    const convertStringToMoneyvalue = (value: String) => {
        if(value === "" || value === "NaN"){
            return "0,00";
        } else if(value.includes(",")){
            if(value.split(",")[1].length === 1){
                return value + "0";
            } else {
                return value;
            }
        } else {
            return value + ",00";
        }
    }

    useEffect(() => {
        get_buget();
    }, []);



    const getFees = (payment: "paypal" | "invoice", type: "tax" | "total" | "additional", budget: number): number => {
        switch(payment){
            case "paypal":
                switch(type){
                    case "tax":
                        return taxRate === null ? 0 : Math.round((getFees("paypal", "additional", budget) + budget) * (taxRate/100) * 100) / 100;
                    case "total":
                        return Math.round((budget + getFees("paypal", "tax", budget) + getFees("paypal", "additional", budget)) * 100) / 100;
                    case "additional":
                        return taxRate === null ? 0 : Math.round((0.39 + (budget + 0.39) / (1 / (1 + (taxRate/100)) - 0.0299) * 0.0299) * 100) / 100;
                    default:
                        return 0;
                }
            case "invoice": 
                switch(type){
                    case "tax":
                        return taxRate === null ? 0 : Math.round(budget * taxRate) / 100; 
                    case "total":
                        return taxRate === null ? 0 : Math.round((budget + getFees("invoice", "tax", budget)) * 100) / 100;
                    default:
                        return 0;
                    }
            default:
                return 0;
        }
    }

    return <div className="fill-space">
        <div className="center">
            <div className="content-container balance">
                <h1 className="header1 header-balance">{_translate("Add credits to your balance")}</h1>
                <div className="balance-input-container">
                    <label className="textlabel balance-description2">{setBudgetText()}</label>
                    <label className="textlabel balance-description">{_translate("How much money would you like to add to your balance?")}</label>
                    <label className="textlabel balance-currencyhint">{_translate("(Seperator: ',' - Currency: €/EUR)")}</label>
                        <input className="inputfield input-balancevalue" onInput={budgetChecker} placeholder="0,00" required type="number"></input>
                    <label className="textlabel balance-insertedValue">{_translate("Budget inserted: ") + convertStringToMoneyvalue(convertFloatSeperationToString(budgetToCharge.toString())).toString() + "€" }</label>
                </div>
                <div className="campaign-input-container">
                    <label className="textlabel campaignlabel">{_translate("Campaign") + " " + _translate("(Maximum 20 Characters)")}</label>
                    <textarea className="inputfield input-campaignvalue" onInput={e => {
                        setCampaign((e.target as HTMLInputElement).value);}} placeholder={_translate("Here you can insert your campaign name").toString()} required></textarea>
                </div>
                {(budgetToCharge.valueOf() > 0 && !(campaign.length > 20))? <div className="campaign-payment-button-container">
                    {manuallyVerified ? <><hr></hr>
                    <div className="payment_button_container payment_button_container_1"> 
                        <label className="textlabel balance-description invoice-button-label invoice-button-label_1">{_translate("Charge via Invoice")}</label> 
                        <label className="textlabel balance-description invoice-button-label invoice-button-label_2">{"(" + 
                            _translate("Total: ") + convertFloatSeperationToString(getFees("invoice", "total", budgetToCharge).toFixed(2)) + "€; " + 
                            _translate("Taxes: ") + convertFloatSeperationToString(getFees("invoice", "tax", budgetToCharge).toFixed(2) + "€)")}</label> 
                        <button className="button submitbutton button-balance-invoice" onClick={() => requestInvoice(budgetToCharge, "EUR", "advance", "", campaign)}>{_translate("Charge via Invoice")}</button>
                    </div></> : <></>}
                    <hr></hr>
                    <div className="payment_button_container payment_button_container_2">
                        <label className="textlabel balance-description paypal-button-label paypal-button-label_1">{_translate("Charge via PayPal")}</label> 
                        <label className="textlabel balance-description paypal-button-label paypal-button-label_2">{"(" + 
                        _translate("Total: ") + convertFloatSeperationToString(getFees("paypal", "total", budgetToCharge).toFixed(2)) + "€; " + 
                        _translate("PayPal Fees: ") + convertFloatSeperationToString(getFees("paypal", "additional", budgetToCharge).toFixed(2)) + "€; " +
                        _translate("Taxes: ") + convertFloatSeperationToString(getFees("paypal", "tax", budgetToCharge).toFixed(2)) + "€)" }</label> 
                        <PayPalButtonWrapper 
                            netto={Number(budgetToCharge.toFixed(2))}
                            brutto={Number(getFees("paypal", "total", budgetToCharge).toFixed(2))}
                            taxes={Number(getFees("paypal", "tax", budgetToCharge).toFixed(2))}
                            additional_fees={Number(getFees("paypal", "additional", budgetToCharge).toFixed(2))} 
                            currency={"EUR"}
                            campaign={campaign}
                            onApprovedCallback={PayPalonApprovedCallback}
                        />
                    </div>
                </div> : <></>}
            </div>
        </div>
    </div>
}

export default Balance