import React from "react";
import {Link, useLocation} from 'react-router-dom'
import _translate from "../Globals/translation";

const ReportRoutes = () => {
    const location = useLocation()

    return <div className='tab-nav'>
        <Link to="/Reports/SendStatistic" className={location.pathname==='/Reports/SendStatistic'?'tab_active':'tab_inactive'}>{_translate("Send Statistic")}</Link>
        {/* <Link to="/Reports/SingleStatistic" className={location.pathname==='/Reports/SingleStatistic'?'tab_active':'tab_inactive'}>{_translate("Single Statistic")}</Link> */}
    </div>;
}

export default ReportRoutes