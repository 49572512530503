import React, { useEffect, useRef, useState } from "react";
import _translate from "../Globals/translation";
import { API_URL } from "../Globals/globalVariables";
import AccountManagerEntry from "./accountManagerEntry";
import { fixedEncodeURIComponent } from "../Globals/globalFunctions";

interface AccountManagerParameters {
    username: string;
    fullName: string;
    userID: string;
    sessionTokenAuth: string;
    maxAccounts: number | null;
}

interface Account {
    userId: number,
    accountType: "main" | "sub",    
    tag: string,
}

const AccountManager: React.FC<AccountManagerParameters> = ({sessionTokenAuth, userID, fullName, username, maxAccounts}) => {
    const [accounts, setAccounts] = useState<Array<Account>>([]);
    const newAccountTagInputRef = useRef<HTMLInputElement>(null);
    
    useEffect(() => {
        getAccounts();
    }, []);

    useEffect(() => {
        // set Title of the Page for SEO reasons
        document.title = "Berlin SMS - "  + _translate("Account Manager");
      }, []);

    const getAccounts = async () => {
        setAccounts([]);
        try {
            let response = await fetch(API_URL + '/subusers',  {method:'GET', 
                headers: {'Authorization': 'Bearer ' + sessionTokenAuth.toString()}});
            if(!response.ok){
                console.warn("get accounts failed");
            } else {
                var accountsTemp: Array<Account> = [];
                let data = await response.json();
                data.users.forEach((user: Account) => { 
                    if(user.accountType === "sub"){
                        accountsTemp.push(user);
                    }
                });
                setAccounts(accountsTemp);
            }    
        } catch (error) {
            console.warn("get accounts failed", error);
        }
    }

    const addAccount = async () => {
        let newAccountTag = ""
        if(newAccountTagInputRef.current?.value === undefined || newAccountTagInputRef.current?.value === null || newAccountTagInputRef.current?.value === "") {
            newAccountTag = _translate("new account").toString();
        } else {
            newAccountTag = newAccountTagInputRef.current?.value;
        }
        try {
            let response = await fetch(API_URL + '/subuser?tag=' + fixedEncodeURIComponent(newAccountTag),  {method:'POST', 
                headers: {'Authorization': 'Bearer ' + sessionTokenAuth.toString(), 'Content-Type': 'application/json'}});
            if(!response.ok){
                console.warn("add account failed");
                let data = await response.json();
                alert(_translate(data.error));
            } else {
                newAccountTagInputRef.current!.value = "";
                getAccounts();
            }
        } catch (error) {
            console.warn("add account failed");
            alert(_translate("Add account failed"))
        }
    }
        
    return <div className="fill-space">
        <div className="center">
            <div className='content-container'>
                { /* display all Profile Information */ }
                <h1 className="header1 header-dashboard">{_translate("Account Manager of") + " " + (fullName.replace(" ", "") === "" ? username : fullName) }</h1>
                {maxAccounts === 0 ? <></> : <span className="textspan maxAccountsSpan">{_translate("You are currently using ").toString() + accounts.length + _translate(" out of ").toString() + maxAccounts + _translate(" subaccouts").toString()}</span>}
                <div className="profile-container">
                    <div className="profileEntrycontainer">
                        {accounts.map((account, index) => {
                            return ( <AccountManagerEntry sessionTokenAuth={sessionTokenAuth} accountTag={account.tag} accountID={account.userId} reloadAccounts={getAccounts} key={index}/>);
                        })}
                    </div>
                    {maxAccounts !== null && accounts.length >= maxAccounts ? <></> :
                        <div className="addProfileContainer">
                            <input className="input profileInput" type="text" placeholder={_translate("new account name").toString()} ref={newAccountTagInputRef} />
                            <button className="button profileButton profileAddbutton" onClick={addAccount}>{_translate("Create new Account")}</button>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
}

export default AccountManager;
