import React, {useEffect, useState} from 'react';
import packageJson from '../package.json';

// components and handler classes
import MainRoutes from './Routes';
import Sidebar  from './components/Globals/Sidebar';
import cookiehandler from './cookies';
import deMessages from "devextreme/localization/messages/de.json";
import { locale, loadMessages } from "devextreme/localization";
import { currentAccountInfo, currentAccountInfoDefault } from './components/Globals/globalVariables';
// styles
import "./assets/_base.css";
import "./assets/_config.css";
import "./assets/Globals_styles.css";
import "./assets/DashBoard_styles.css";
import "./assets/Reports_styles.css";
import "./assets/Login_styles.css";
import "./assets/send_sms_styles.css";
import "./assets/jquery.dataTables.min.css"; 
import 'devextreme/dist/css/dx.light.css'; // styles welche für die datagrids benötigt werden -> sind aus der devextreme Bibliothek
import "./assets/invoice_styles.css";
import "./assets/balance_styles.css";
import "./assets/fcm_styles.css";
import "./assets/KeyGeneration_styles.css";
import "./assets/twoFAHeader_styles.css";
import "./assets/twoFAEditSite_styles.css";
import "./assets/TwoFAComponent_styles.css";


function App() {
    const [isLoggedIn, setIsLoggedIn] = useState<Boolean>(false);
    const [currentAccount, setCurrentAccount] = useState<currentAccountInfo>(currentAccountInfoDefault);

    useEffect(() => {
        validateLogin();
        const language = cookiehandler.getCookie('language');

        if (language === "") { // if no language is set
            cookiehandler.setCookie("language", "Ger", "/", window.location.hostname); // set the language to german
        }
        if (language === "Eng") { // if the language is set to english
            document.documentElement.lang = "en"; // set the language to english
            locale("en"); // set the language to english
        } else if (language === "Ger") { // if the language is set to german
            document.documentElement.lang = "de"; // set the language to german
            loadMessages(deMessages); // load the german messages
            locale(navigator.language); // set the language to german
        }
    }, []);

    function logout() {
        setIsLoggedIn(false);
        // delete cookie to prevent automatic login
        cookiehandler.delete_cookie("apikey", "/", window.location.hostname);
        cookiehandler.delete_cookie("main-apikey", "/", window.location.hostname); 
        cookiehandler.delete_cookie("sessionTokenAuth", "/", window.location.hostname); 
    }

    async function validateLogin() {
        // check if api token exists 
        var _sessionTokenAuth: string = cookiehandler.getCookie("sessionTokenAuth");
        if(_sessionTokenAuth !== undefined && _sessionTokenAuth !== ""){
            cookiehandler.validate_SessionTokenUid(_sessionTokenAuth,
                (_isLoggedIn: Boolean) => setIsLoggedIn(_isLoggedIn),
                (_currentAccount: currentAccountInfo) => setCurrentAccount(_currentAccount));
        }
    }

    return (  
        <div className="App">
            {/**  Sidebar **/}
            <Sidebar        logout={() => logout()}
                            isLoggedIn={isLoggedIn}
                            accountType={currentAccount.accountType}
                            maxAccounts={currentAccount.maxAccounts}
                            WEBSITEVERSIONNUMBER={packageJson.version}/>

            {/**  inner content **/}
            <MainRoutes     isLoggedin={isLoggedIn}
                            apikey={currentAccount.apikey}
                            userId={currentAccount.userId}
                            accountType={currentAccount.accountType}
                            tag={currentAccount.tag}
                            userName={currentAccount.userName}
                            fullName={currentAccount.fullName}
                            phonenumbers={currentAccount.phoneNumber}
                            recharge_amount={currentAccount.recharge_amount}
                            maxAccounts={currentAccount.maxAccounts}
                            lang={currentAccount.lang}
                            prepaid={currentAccount.prepaid}
                            taxRate={currentAccount.taxRate}
                            manuallyVerified={currentAccount.manuallyVerified}
                            sessionTokenAuth={currentAccount.sessionTokenAuth}
                            validateLogin={() => validateLogin()}/>
        </div>
    );
}

export default App;
