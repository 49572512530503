import React from "react";
import { Routes, Route, Navigate} from 'react-router-dom'
import Dashboard from "./components/Dashboard/Dashboard";
import Reports from "./components/Reports/Reports";
import SendSMS from "./components/MessageSending/send_sms";
import InvoiceOverview from "./components/invoce/invoice";
import Balance from "./components/Balance/balance";

import SendStatistic from "./components/Reports/SendStatistic";
// import SingeStatistics from "./components/Reports/SingeStatistics";
import Login from "./components/Login/login";
import Register from "./components/Login/register";
import Profile from "./components/Profile/profile";
import AccountManager from "./components/AccountManager/accountDisplay";
import TwoFASite from "./components/TwoFA/twoFA-Site";
//import Register_HCaptcha from "./components/Login/register_HCaptcha";
//import Register_ReCaptcha from "./components/Login/register_ReCaptcha";
// Route war vorher für default gedacht -> leitet aber jetzt direkt aufs Dashboard weiter


interface RoutingParameters {
    isLoggedin: Boolean,
    apikey: string,
    userId: string,
    accountType: "sub" | "main",
    tag: string,
    userName: string,
    fullName: string,
    phonenumbers: string,
    recharge_amount: number | null,
    maxAccounts: number | null,
    lang: string,
    prepaid: boolean | null,
    taxRate: number | null,
    manuallyVerified: boolean | null,
    sessionTokenAuth: string,
    validateLogin: () => void;
}

const MainRoutes: React.FC<RoutingParameters> = ({isLoggedin, apikey, userId, accountType, tag, userName, fullName, recharge_amount, maxAccounts, lang, prepaid, taxRate, manuallyVerified, sessionTokenAuth, validateLogin}) => (
    // if user is not logged in redirect to login
    // if user is logged in redirect to dashboard 
    isLoggedin ? 
    <Routes>
        <Route path="*" element={<Navigate replace to="/dashboard"/>}/>
        <Route path="/" element={<Navigate replace to="/dashboard"/>}/>
        <Route path="/dashboard" element={<Dashboard sessionTokenAuth={sessionTokenAuth} userID={userId} username={userName} fullName={fullName} accountType={accountType} maxAccounts={maxAccounts} event={undefined}/>}/>
        <Route path="/profile" element={<Profile apikey={apikey} sessionTokenAuth={sessionTokenAuth} userID={userId} username={userName} fullName={fullName} accountType={accountType}/>}/> 
        <Route path="/Reports" element={<Reports/>}>
            <Route path="/Reports" element={<Navigate replace to="/Reports/SendStatistic"/>}/>
                {/* <Route path="/Reports/SingleStatistic" element={<SingeStatistics apikey={apikey} userid={userId} username={userName} />}/> */}
                <Route path="/Reports/SendStatistic" element={<SendStatistic sessionTokenAuth={sessionTokenAuth} userid={userId} username={userName} />}/>
        </Route> 
        <Route path="/sendsms" element={ <SendSMS apikey={apikey}/> }/>
        {/* accountType "sub" does not have Invoices or Balance */}
        {accountType === "main" ? <>
            <Route path="/invoices" element={<InvoiceOverview sessionTokenAuth={sessionTokenAuth} userid={userId} username={userName}/>}/>
            <Route path="/balance" element={<Balance sessionTokenAuth={sessionTokenAuth} prepaid={prepaid} taxRate={taxRate} manuallyVerified={manuallyVerified}/>}/> 
            <Route path="/keygen" element={<TwoFASite sessionTokenAuth={sessionTokenAuth} userID={userId}/>}/>
            { maxAccounts !== null && maxAccounts > 0 ? <Route path="/account-manager" element={<AccountManager sessionTokenAuth={sessionTokenAuth} userID={userId} username={userName} fullName={fullName} maxAccounts={maxAccounts}/>}/> : <></> }
            {/* <Route path="/account-manager" element={<AccountManager apikey={apikey} userID={userId} username={userName} fullName={fullName} maxAccounts={maxAccounts}/>}/> */}
            </> 
        : <></>}
        {/*  Special Event Routes  */}
        <Route path="/login-ostern24" element={<Navigate replace to="/dashboard-ostern24"/>}/>
        <Route path="/register-ostern24" element={<Navigate replace to="/dashboard-ostern24"/>}/>
        <Route path="/dashboard-ostern24" element={<Dashboard sessionTokenAuth={sessionTokenAuth} username={userName} fullName={fullName} accountType={accountType} userID={userName} maxAccounts={maxAccounts} event="ostern24"/>}/>
    </Routes>
    :
    <Routes>
        <Route path="*" element={<Login validateLogin={validateLogin} event={undefined}/>}/>
        <Route path="/" element={<Navigate replace to="/login"/>}/>
        <Route path="/login" element={<Login validateLogin={validateLogin} event={undefined}/>}/>
        {/* <Route path="/register" element={<Register event={undefined}/>}/> */}
        {/* Special Event Routes */}
        <Route path="/login-ostern24" element={<Login validateLogin={validateLogin} event="ostern24"/>}/>
        {/* <Route path="/register-ostern24" element={<Register event="ostern24"/>}/> */}
    </Routes>
)

export default MainRoutes;