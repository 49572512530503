import React from "react";
import translate from "./translation";
import { verificationTypes } from "./TwoFATypes";

interface TwoFAAddressInputParameters {
    verificationType: verificationTypes;
    _t: translate;
    onAddressSubmit: (verificationAdress: string) => void;
};
interface TwoFAAddressInputState {
    verificationaddress: string;
    isaddressValid: boolean;
};
class TwoFAAddressInput extends React.Component<TwoFAAddressInputParameters, TwoFAAddressInputState> {
    constructor(props: TwoFAAddressInputParameters) {
        super(props);
        // binding the functions to the class
        this.state = {
            verificationaddress: "",
            isaddressValid: false,
        }
    }

    onInputPhone2 = (e: React.FormEvent<HTMLInputElement>) => {
        var phonenumber: string = (e.target as HTMLInputElement).value;
        phonenumber = phonenumber.replace(/\(0\)/g, '').replace(/[^\d\+]/g, '');
    
        if (!phonenumber.match(/^(\+|00)/)) {
            if (phonenumber.substr(0, 2) === '01') { phonenumber = '49' + phonenumber.slice(1); }
            if (phonenumber.substr(0, 2) === '15') { phonenumber = '49' + phonenumber; }
            if (phonenumber.substr(0, 2) === '16') { phonenumber = '49' + phonenumber; }
            if (phonenumber.substr(0, 2) === '17') { phonenumber = '49' + phonenumber; }
            if (phonenumber.substr(0, 2) === '06') { phonenumber = '43' + phonenumber.slice(1); }
            if (phonenumber.substr(0, 2) === '60') { phonenumber = '43' + phonenumber; }
            if (phonenumber.substr(0, 2) === '65') { phonenumber = '43' + phonenumber; }
            if (phonenumber.substr(0, 2) === '66') { phonenumber = '43' + phonenumber; }
            if (phonenumber.substr(0, 2) === '67') { phonenumber = '43' + phonenumber; }
            if (phonenumber.substr(0, 2) === '68') { phonenumber = '43' + phonenumber; }
        }
    
        phonenumber = phonenumber.replace(/\+/g, '').replace(/^0/, '');
        if (phonenumber.length < 10 || phonenumber.length > 14) {
            this.setState({verificationaddress: "", isaddressValid: false})
            return;
        }
        this.setState({verificationaddress: phonenumber, isaddressValid: true})
    };

    onInputPhone = (e: React.FormEvent<HTMLInputElement>) => {
        var inputnumber: string = (e.target as HTMLInputElement).value;
        const minlength: number = 8;
        const maxlength: number = 19;

        var _phonenumber: string = inputnumber.replaceAll(" ", "").replaceAll("+", "");
        // check if the input is a number
        if(!/^\d+$/.test(_phonenumber) && _phonenumber !== ""){
            this.setState({verificationaddress: "", isaddressValid: false});
            return;
        }

        if(_phonenumber !== null && _phonenumber !== ""){
            // check for the country code
            if(_phonenumber.slice(0, 4) === "0049"){
                _phonenumber = "49"+ _phonenumber.slice(4);
            } else if(_phonenumber.slice(0, 3) === "049"){
                _phonenumber = "49"+ _phonenumber.slice(3);
            } else if(_phonenumber.slice(0, 1) === "0"){
                // remove the first 0
                _phonenumber = "49" + _phonenumber.slice(1);
            }
            // check if the number is too long or too short
            if(_phonenumber.length - 2 < minlength || _phonenumber.length - 2 > maxlength){
                this.setState({verificationaddress: "", isaddressValid: false});
                console.warn("TWOFA BSMS - Phone number is too long or too short");
                return;
            } else { 
                this.setState({verificationaddress: _phonenumber, isaddressValid: true});
            }
        }
        else {
            this.setState({verificationaddress: "", isaddressValid: false});
        }        
    };
    // function to validate the email
    validateEmail = (email: string) => {
        return String(email)
          .toLowerCase()
          .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    onInputMail = (e: React.FormEvent<HTMLInputElement>) => {
        const userinput = e.currentTarget.value;
        if(this.validateEmail(userinput)){
            this.setState({verificationaddress: userinput, isaddressValid: true});
        } else {
            this.setState({verificationaddress: userinput, isaddressValid: false});
        }
    }

    onAddressSubmit = () => {
        this.props.onAddressSubmit(this.state.verificationaddress);
    };


    render (): React.ReactNode {
        return (<div className="twofacomponent_container twofacomponent_container_phoneForm">
            {this.props.verificationType === "optin(sms)" ?
                <>
                <label className="descr-label TwoFAComponent_DescriptionSpan TwoFAComponent_Label_EnterPhonenumber">{this.props._t.translate("Phonenumber")}</label>
                <input className="input TwoFAComponent_Input TwoFAComponent_phonenumber_input" type="text" onInput={this.onInputPhone} required placeholder="+49123456789"></input>
                { this.state.isaddressValid ? <button className="button TwoFAComponent_Button TwoFAComponent_Button_SendCode" onClick={this.onAddressSubmit}>{this.props._t.translate("Login")}</button> : null}
                </>
            : this.props.verificationType === "optin(mail)" ?
                <>                             
                <label className="descr-label TwoFAComponent_DescriptionSpan TwoFAComponent_Label_EnterPhonenumber">{this.props._t.translate("E-Mail")}</label>
                <input className="input TwoFAComponent_Input TwoFAComponent_phonenumber_input" type="text" onInput={this.onInputMail} required placeholder="max@mustermail.de"></input>
                { this.state.isaddressValid ? <button className="button TwoFAComponent_Button TwoFAComponent_Button_SendCode" onClick={this.onAddressSubmit}>{this.props._t.translate("Login")}</button> : null}
                </> : <></>}
        </div>);
    }
}
export default TwoFAAddressInput;