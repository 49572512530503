import React, { useEffect, useState } from "react";
import _translate from "../Globals/translation";
import { API_URL } from "../Globals/globalVariables";
import { fixedEncodeURIComponent } from "../Globals/globalFunctions";

interface ProfileParameters {
    apikey: string;
    sessionTokenAuth: string;
    username: string;
    fullName: string;
    userID: string;
    accountType: "sub" | "main";
}
interface AccountReduced {
    userId: Number,
    accountType: "sub" | "main",
    recharge_amount: Number,
    maxAccounts: Number,
    lang: "de" | "en",
    userName: string,
    tag: string,
    fullName: string,
    phonenumber: string
}

const Profile: React.FC<ProfileParameters> = ({apikey, sessionTokenAuth, userID, username, fullName, accountType}) => {
    useEffect(() => {
        // set Title of the Page for SEO reasons
        document.title = "Berlin SMS - "  + _translate("Profile");
      }, []);

    const [account, setAccount] = useState<AccountReduced>({userId: 123, 
                                                                  accountType: "sub",
                                                                  recharge_amount: 172.5,
                                                                  maxAccounts: 0,
                                                                  lang: "de",
                                                                  userName: "MaxM@email.de",
                                                                  tag: "Max",
                                                                  fullName: "Max Mustermann",
                                                                  phonenumber: "+49(0)30-5510.2449"});
    const [oldPassword, setOldPassword] = useState<string>(""); 
    const [newPassword, setNewPassword] = useState<string>(""); 
    const [confirmPassword, setConfirmPassword] = useState<string>("");

    // show hide passwords
    const [showPasswordNew, setShowPasswordNew] = useState<boolean>(false); 
    const [showPasswordOld, setShowPasswordOld] = useState<boolean>(false); 
    const passwordInputtRefNew = React.useRef<HTMLInputElement>(null);
    const passwordInputtRefOld = React.useRef<HTMLInputElement>(null);
    const showPasswordButtonRefNew = React.useRef<HTMLButtonElement>(null);
    const showPasswordButtonRefOld = React.useRef<HTMLButtonElement>(null);
    
    const passwordErrorRef = React.useRef<HTMLSpanElement>(null);
    const saveNewPasswordRef = React.useRef<HTMLButtonElement>(null);

    const togglePasswordVisibilityNew = () => setShowPasswordNew(!showPasswordNew);
    const togglePasswordVisibilityOld = () => setShowPasswordOld(!showPasswordOld);
    
    useEffect(() => {
        if(passwordInputtRefNew.current !== null && showPasswordButtonRefNew.current !== null){
            if(showPasswordNew){
                passwordInputtRefNew.current.type = "text";
                showPasswordButtonRefNew.current.textContent = _translate("Hide").toString();
            } else {
                passwordInputtRefNew.current.type = "password";
                showPasswordButtonRefNew.current.textContent = _translate("Show").toString();
            }
        }
    }, [showPasswordNew]);
    useEffect(() => {
        if(passwordInputtRefOld.current !== null && showPasswordButtonRefOld.current !== null){
            if(showPasswordOld){
                passwordInputtRefOld.current.type = "text";
                showPasswordButtonRefOld.current.textContent = _translate("Hide").toString();
            } else {
                passwordInputtRefOld.current.type = "password";
                showPasswordButtonRefOld.current.textContent = _translate("Show").toString();
            }
        }
    }, [showPasswordOld]);


    const getAccountInfo = async () => {
        // console.log("userID: " + userID);
        // only main accounts can fetch the account information
        if(accountType === "sub") return;
        try {
            let response = await fetch(API_URL + '/currentuser/',  {method:'GET', 
                headers: {'Authorization': 'Bearer ' + sessionTokenAuth.toString()}});
            if(!response.ok){
                console.warn("No Account found");
            } else {
                let data = await response.json();
                setAccount(
                    {userId: data.user.userId, 
                    accountType: data.user.accountType,
                    recharge_amount: data.user.recharge_amount,
                    maxAccounts: data.user.maxAccounts,
                    lang: data.user.lang,
                    userName: data.user.userName,
                    tag: data.user.tag,
                    fullName: data.user.fullName,
                    phonenumber: data.user.phonenumber}
                );
            }
        } catch (error) {
            console.warn("No Account found");
        }
    }

    const saveNewPassword = async () => {
        // check if the tag is empty
        if(account.tag === "" || account.tag === undefined || account.tag === null) {
            window.alert(_translate("Tag can not be empty"));
            return;
        }
        // check if the password is empty
        if(newPassword === "" || newPassword === undefined || newPassword === null ||
            newPassword.length < 8 || newPassword.length > 64 ||
            // check if there is a uppercase letter, a lowercase letter and a special character
            !newPassword.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,64}$/)) {
            window.alert(_translate("Password can not be empty. A password must contain at least 8 characters, one uppercase letter and one special character"));
            return;
        }        
        // sending the request
        // encode the password and tag so that the url is valid and the request can be send
        try {
            let response = await fetch(API_URL + "/currentuser/", 
                {method:'PUT', 
                headers: {'Authorization': 'Bearer ' + sessionTokenAuth.toString(), 'Content-Type': 'application/json'},
                body: JSON.stringify({lang: account.lang, newPassword: newPassword, oldPassword: oldPassword})
            });
            if(!response.ok){
                console.warn("Saving Account failed");
                let data = await response.json();
                window.alert("Saving Account failed! Errorcode: " + data.error);
            } else {
                console.log("Account saved");
                window.alert(_translate("Account saved"));
            }
        } catch (error) {
            console.warn("Saving Account failed");
            window.alert("Saving Account failed!");
        }
    }
    const copyToClipboard= (item: string) => {
        navigator.clipboard.writeText(item);
    }

    useEffect(() => {
        if(newPassword !== "" && confirmPassword !== ""){
            if(newPassword !== confirmPassword){
                if (saveNewPasswordRef.current !== null) {
                    saveNewPasswordRef.current.disabled = true;
                } if (passwordErrorRef.current !== null) {
                    passwordErrorRef.current.textContent = _translate("Passwords do not match").toString();
                }
            } else if (!newPassword.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,64}$/)) {
                if (saveNewPasswordRef.current !== null) {
                    saveNewPasswordRef.current.disabled = true;
                } if (passwordErrorRef.current !== null) {
                    passwordErrorRef.current.textContent = _translate("Password must contain at least 8 characters, one uppercase letter and one special character").toString();
                }
            } else {
                if (saveNewPasswordRef.current !== null) {
                    saveNewPasswordRef.current.disabled = false;
                } if (passwordErrorRef.current !== null) {
                    passwordErrorRef.current.textContent = "";
                }
            }
        } else {
            if (saveNewPasswordRef.current !== null) {
                saveNewPasswordRef.current.disabled = true;
            } if (passwordErrorRef.current !== null) {
                passwordErrorRef.current.textContent = "";
            }
        }
    }, [newPassword, confirmPassword]);

    useEffect(() => {
        getAccountInfo();
        if (saveNewPasswordRef.current !== null) {
            saveNewPasswordRef.current.disabled = true;
        } if (passwordErrorRef.current !== null) {
            passwordErrorRef.current.textContent = "";
        }
    }, []);

    return <div className="fill-space">
        <div className="center">
            <div className='content-container dashboard'>
                { /* display all Profile Information */ }
                <h1 className="header1 header-dashboard">{_translate("Profile")}</h1>
                <div className="profile-container">
                    <div className="profile-container profile-container-username">
                        <span className="textspan profile-textspan profile-textspan-username">{_translate("Username")}:</span>
                        <input className="inputt profile-input profile-input-username2" disabled value={username}/>
                    </div>
                    {/* <div className="profile-container profile-container-accounttype">
                        <span className="textspan profile-textspan profile-textspan-accounttype">{_translate("Account Type")}:</span>
                        <input className="inputt profile-input profile-input-accounttype2" disabled value={accountType}/>
                    </div> */}
                    <div className="profile-container profile-container-apikey">
                        <span className="textspan profile-textspan profile-textspan-apikey">{_translate("API-Key")}:</span>
                        <input className="inputt profile-input profile-input-apikey2" disabled value={apikey}/>
                        <button title={_translate("copy").toString()} className="button button-twoFA-content-twoFA-sitekey" onClick={()=>copyToClipboard(apikey)}>&#128203;</button>
                    </div>
                    { accountType === "main" ? <>
                        <span className="textspan profile-textspan profile-textspan-password-topic">{_translate("Change Password")}:</span>
                        <span className="textspan profile-textspan profile-textspan-password-topic-2">{_translate("Be careful! If you use server scripts created by yourself you need to change the password there aswell.")}</span>
                        <div className="profile-container profile-container-password">
                            <span className="textspan profile-textspan profile-textspan-password">{_translate("Old Password")}:</span>
                            <input className="inputt profile-input profile-input-password" ref={passwordInputtRefOld} onInput={(e: React.ChangeEvent< HTMLInputElement>) => setOldPassword(e.target.value)} type="password" placeholder="********"/>
                            <button className="button profile-button profile-button-password" ref={showPasswordButtonRefOld} onClick={togglePasswordVisibilityOld}>{_translate("Show")}</button>
                        </div>
                        <div className="profile-container profile-container-password">
                            <span className="textspan profile-textspan profile-textspan-password">{_translate("New Password")}:</span>
                            <input className="inputt profile-input profile-input-password" ref={passwordInputtRefNew} onInput={(e: React.ChangeEvent< HTMLInputElement>) => setNewPassword(e.target.value)} type="password" placeholder="********"/>
                            <button className="button profile-button profile-button-password" ref={showPasswordButtonRefNew} onClick={togglePasswordVisibilityNew}>{_translate("Show")}</button>
                        </div>
                        <div className="profile-container profile-container-password">
                            <span className="textspan profile-textspan profile-textspan-password-2">{_translate("Confirm Password")}:</span>
                            <input className="inputt profile-input profile-input-password" onInput={(e: React.ChangeEvent< HTMLInputElement>) => setConfirmPassword(e.target.value)} type="password" placeholder="********"/>
                            <button className="button profile-button profile-button-password-save" ref={saveNewPasswordRef} onClick={saveNewPassword}>{_translate("Save new Password")}</button>
                        </div>
                        <span className="error" ref={passwordErrorRef}></span>

                    </> : <></>}
                </div>
            </div>
        </div>
    </div>
}

export default Profile

