import { API_URL, currentAccountInfo, currentAccountInfoDefault } from "./components/Globals/globalVariables";

// code is based on https://stackoverflow.com/questions/2144386/how-to-delete-a-cookie
class cookiehandler {
    static delete_cookie(name: string, path: string, domain: string): void {
        if( this.getCookie( name ) ) {
          document.cookie = name + "=" +
            ((path) ? ";path="+path:"") +
            ((domain)?";domain="+domain.replace("dev","").replace("app.","").replace("test","").replace("twofa.","").replace("portal.",""):"") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT;SameSite=None;Secure=true";
        }
    }
    static getCookie(name: string) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        var cookie: string | undefined = "";
        if (parts.length === 2) cookie = parts.pop()?.split(';').shift();
        if(cookie === undefined) return ""; else return cookie;
    }

    static setCookie(name: String, value: String, path: String, domain: string, expiresIn = (7 * 24 * 60 * 60 * 1000), secure = true, sameSite = "None"){
        const date = new Date();
        // Set it expire date - default is in 7 days
        date.setTime(date.getTime() + expiresIn);
        document.cookie = name + "=" + value + "; expires=" + date.toUTCString() + "; path=" + path + "; domain=" + domain.replace("dev","").replace("test","").replace("app.","").replace("twofa.","").replace("www.","").replace("portal.","") + "; SameSite=" + sameSite + "; Secure=" + secure;            
    }

    static validate_SessionTokenUid = async (sessionTokenAuth: string,
                                  setIsLoggedInFunction: (_isLoggedIn: Boolean) => void,
                                  setCurrentAccount: (_currentAccount: currentAccountInfo) => void,) => {
        // request the current budget to validate the apikey
        try{
            let response = await fetch(API_URL + '/currentuser', {method:'GET', 
            headers: {'Authorization': 'Bearer ' + sessionTokenAuth.toString()}});
            if (response.ok){
                let data = await response.json();
                setCurrentAccount(
                    {
                        userId: data.user.userId,
                        accountType: data.user.accountType,
                        tag: data.user.tag,
                        apikey: data.user.apikey,
                        userName: data.user.userName,
                        fullName: data.user.fullName,
                        phoneNumber: data.user.phoneNumber,
                        recharge_amount: data.user.recharge_amount,
                        maxAccounts: data.user.maxAccounts,
                        lang: data.user.lang,
                        prepaid: data.user.prepaid,
                        taxRate: data.user.taxRate,
                        manuallyVerified: data.user.manuallyVerified,
                        sessionTokenAuth: sessionTokenAuth
                    }
                )
                setIsLoggedInFunction(true);
            } else {
                throw new Error("Sessionkey Key not valid");
            }

        } catch (error) {
            setCurrentAccount(currentAccountInfoDefault);
            setIsLoggedInFunction(false);
            return;
        }
    }
}

export default cookiehandler;