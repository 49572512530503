// using usestates to save the user input
import React from "react";
import translate from "./translation";
import TwoFACaptchaLoader from "./TwoFACaptchaLoader";
import {API_URL, TwoFAParameters, TwoFAState, defaultTwoFAState} from "./TwoFATypes";
import TwoFACodeInput from "./TwoFACodeInput";
import TwoFAAddressInput from "./TwoFAAddressInput";

class TwoFA extends React.Component<TwoFAParameters, TwoFAState> {
    // local variables
    private _t = new translate();   
    // constructor
    constructor(props: TwoFAParameters) {
        super(props); // call the constructor of the parent class
        this.reset = this.reset.bind(this); // bind the function to the class
        if(this.props.bsmsLanguage) this._t.setLanguage(this.props.bsmsLanguage); // set the language if it is provided
        this.state = defaultTwoFAState; // set the initial state
    }
    // External functions
    componentDidMount() { // initial load
        this.reset(); // reset the component
    }
    reset = () => {
        //console.log("TWOFA BSMS - reset called");
        this.setState(defaultTwoFAState);
        this.validateAPI();
    }
    /* ------------------------------------------------- */
    /* ----------------- API functions ----------------- */
    /* ------------------------------------------------- */
    validateAPI = async () => {
        this.setState({renderStatus: "loading"});
        try{
            let response = await fetch(API_URL+'/twofa/account?twofaSitekey='+this.props.bsmsSitekey, {method: "GET"});
            if(response.ok){
                let data = await response.json();
                if(data.domains.includes(window.location.hostname)){
                    if(data.twofaType === "optin(sms)" || data.twofaType === "optin(mail)" || data.twofaType === "optin(mail+sms)" || data.twofaType === "sms-auth" || data.twofaType === "mail-auth" || data.twofaType === "oauth2") this.setState({verificationType: data.twofaType});
                    this.setState({captchaSitekey: data.captchaSitekey, apiReady: true, captchaType: data.captchaType, renderStatus: "initialMethod"});
                } else {
                    throw new Error("BerlinSMS Sitekey does not match your current domain!");
                }
            } else {
                throw new Error("API responded not as expected!");
            }
        } catch (error) {
            let errormessage = typeof error === "string" ? this._t.translate(error) : this._t.translate("API not available!");
            this.setState({apiReady: false, errorMessage:errormessage.toString(), errorOccured: true, renderStatus: "error"});
            if(this.props.onError !== undefined) this.props.onError("API not ready");
        }
    }    
    sendInitialCode = async (captchaToken: string) => {
        // building the url
        var url = API_URL;
        if(this.state.verificationType === "optin(sms)"){
            url += "/twofa/sendsms?twofaSitekey=" + this.props.bsmsSitekey.replaceAll('"', '');   
            url += "&captchaToken=" + captchaToken;
            url += "&phonenumber=" + this.state.verificationAddress;    
        } else if(this.state.verificationType === "optin(mail)"){
            url += "/twofa/sendmail?twofaSitekey=" + this.props.bsmsSitekey.replaceAll('"', '');  
            url += "&captchaToken=" + captchaToken;
            url += "&mailaddress=" + encodeURIComponent(this.state.verificationAddress);
        }
        // sending the request
        this.setState({renderStatus: "loading"});
        try{
            let response = await fetch(url, {method:'POST'});
            if(response.ok){
                let data = await response.json();
                this.setState({apiReady: true, codeSend: true, challengeToken: data.challengeToken, renderStatus: "codeInput"});
            } else {
                throw new Error("Failed to send code. - Most likely the Form was not correctly filled or the number of attempts ran out.");
            }
        } catch (error) {
            let errormessage = typeof error === "string" ? this._t.translate(error) : this._t.translate("Failed to send code.");
            this.setState({errorMessage:errormessage.toString(), errorOccured: true, renderStatus: "error"});
            if(this.props.onError !== undefined) this.props.onError("Failed to send code. - Most likely the Form was not correctly filled or the number of attempts ran out.");
        }
    }
    verifyInitialCode = async (code: string) => {
        let url = API_URL + "/twofa/preverifycode?code=" + code + "&twofaSitekey=" + this.props.bsmsSitekey.replaceAll('"', '') + "&challengeToken=" + this.state.challengeToken;
        // sending the request
        this.setState({renderStatus: "loading"});
        try {
            let response = await fetch(url, {method:'PUT'});
            if(response.ok) {
                let data = await response.json();
                console.log("TWOFA BSMS - Validating code for 2fa Success! Data:\n", data);
                this.setState({verificationAddress: data.verifiedAddress, renderStatus: "loading"});
                this.startSession(); // start the session after the code was verified
            } else if (response.status === 401) { // cant verify the code
                throw new Error("Error: Failed to validate the Code.");
            } else if (response.status === 403) { // worng code but still possible to try again
                let data = await response.json();
                if(data.limitAttempts === 0 || data.limitAttempts === undefined || data.limitAttempts === null || data.limitAttempts === "" || data.limitAttempts === "0" || isNaN(parseInt(data.limitAttempts))) 
                    throw new Error("Failed to validate the Code. Error: No more tries."); // no more tries
                else this.setState({limitAttempts: parseInt(data.limitAttempts), apiReady: true, renderStatus: "codeInput"}); // still tries left
            } else if (response.status === 404) { // worng or expired challengeToken
                throw new Error("Failed to validate the Code. Error: challengeToken not found.");
            }
        } catch (error) {
            let errormessage = typeof error === "string" ? this._t.translate(error) : this._t.translate("Failed to validate the Code.");
            this.setState({errorMessage:errormessage.toString(), errorOccured: true, renderStatus: "error"});
            if(this.props.onError !== undefined) this.props.onError("Failed to validate the Code. Error: No more tries.");
            if(this.props.onExpire !== undefined) this.props.onExpire();
        }
    }
    startSession = async () => {
        this.setState({renderStatus: "loading"});
        try {
            let response = await fetch(API_URL + "/start/session",
                {method:'POST', 
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({challengeToken: this.state.challengeToken})});
            if(response.ok) {
                let data = await response.json();
                if(this.props.onVerify !== undefined) this.props.onVerify(data.token);
                this.setState({renderStatus: "verified"});
            } else {
                throw new Error("Failed to start session.");
            }
        } catch (error) {
            let errormessage = typeof error === "string" ? this._t.translate(error) : this._t.translate("Failed to start session.");
            this.setState({errorMessage:errormessage.toString(), errorOccured: true, renderStatus: "error"});
            if(this.props.onError !== undefined) this.props.onError("Failed to start session.");
        }
    }

    /* ----------------------------------------------------- */
    /* ----------------- CAPTCHA functions ----------------- */
    /* ----------------------------------------------------- */
    private captchaLoaderRef = React.createRef<TwoFACaptchaLoader>();
    onCaptchaChange = (token: string | null | undefined) => {
        if(token === null || token === undefined || token === ""){
            this.setState({captchaToken: "", errorMessage: this._t.translate("Error: Captcha Token is null or undefined.").toString(),errorOccured: true, renderStatus: "error"});
            console.warn("recaptcha token is null or undefined");
            if(this.props.onError !== undefined) this.props.onError("captcha token is null or undefined");
        } else {
            this.setState({captchaToken: token, renderStatus: "loading"});
            this.sendInitialCode(token);
        }
    }
    onCaptchaExpired = () => {
        this.reset();        
    }
    onCaptchaError = (error: string) => {
        console.warn("onCaptchaError called.");
        this.setState({errorMessage: this._t.translate("Error: ").toString() + error,errorOccured: true});
        if(this.props.onError !== undefined) this.props.onError("onCaptchaError called - error: Captcha-2");
    }

    /* --------------------------------------------------- */
    /* ----------------- INITIAL METHODS ----------------- */
    /* --------------------------------------------------- */
    onAddressSubmit = (address: string) => {
        if(address === undefined || address === null || address === "") this.setState({verificationAddress: "", renderStatus: "initialMethod"});
        else this.setState({verificationAddress: address, renderStatus: "captcha"});
    }
    onValidationCodeSubmit = (code: string) => {
        if(code === undefined || code === null || code.length !== 6){
            alert(this._t.translate("Please enter a valid code! (6 digits)"));
            this.setState({validationCode: "", renderStatus: "codeInput"});
        } else {
            this.setState({validationCode: code, renderStatus: "loading"});
            this.verifyInitialCode(code);
        }
    }

    /* -------------------------------------------------- */
    /* ----------------- HTML functions ----------------- */
    /* -------------------------------------------------- */
    renderContent(): React.ReactNode {
        switch(this.state.renderStatus){
            case "captcha":
                return  <TwoFACaptchaLoader
                            ref={this.captchaLoaderRef}
                            captchaSitekey={this.state.captchaSitekey}
                            captchaType={this.state.captchaType}
                            onChange={this.onCaptchaChange}
                            onExpire={this.onCaptchaExpired}
                            onError={this.onCaptchaError}
                        />
            case "initialMethod":
                return  <TwoFAAddressInput
                            verificationType={this.state.verificationType}
                            _t={this._t}
                            onAddressSubmit={this.onAddressSubmit}
                        />
            case "codeInput":
                return  <TwoFACodeInput
                            verificationAddress={this.state.verificationAddress}
                            verificationType={this.state.verificationType}
                            triesLeft={this.state.limitAttempts}
                            _t={this._t}
                            onCodeSumbit={this.onValidationCodeSubmit}
                            reset={this.reset}
                        />
            case "loading":
                return  <div className="twofacomponent_container twofacomponent_container_Loading">
                            <div className="CodeValidation_LoadingAnimation"></div>
                            <label className="descr-label TwoFAComponent_DescriptionSpan TwoFAComponent_Label_Loading">{this._t.translate("Loading...")}</label>
                        </div>
            case "verified":
                return  <div className="twofacomponent_container twofacomponent_container_Verified">
                            <label className="descr-label TwoFAComponent_DescriptionSpan TwoFAComponent_Label_Verified">
                                {this._t.translate(" verified.")}
                            </label>
                        </div>
            case "error":
                return  <div className="twofacomponent_container twofacomponent_container_error">
                            <label className="descr-label TwoFAComponent_DescriptionSpan TwoFAComponent_Label_error bsmstwofa-error">{this.state.errorMessage}</label> 
                            {this.state.apiReady ? <button className="button TwoFAComponent_Button TwoFAComponent_Button_checkCode" onClick={this.reset}>{this._t.translate("Try again")}</button> : <></>}
                        </div>
            default:
                return  <></>
        }
    }
    render(): React.ReactNode {
        return  <div className="TwoFAComponent">
                    {this.renderContent()}
                        <div className="bsmstwofa-logo"></div>
                        <div className="twofa_namecontainer">TWOFA-VERIFY</div>
                        <div className="twofa_additionallinks">
                            <a className="hrefLink Datenschutz" onClick={() => window.location.assign("https://www.berlinsms.de/datenschutz")} href="https://www.berlinsms.de/datenschutz">Datenschutzerklärung</a> -
                            <a className="hrefL--template typescript ink AGBs"  onClick={() => window.location.assign("https://www.berlinsms.de/twofa-nutzungsbedingungen/")} href="https://www.berlinsms.de/agb">Nutzungsbedingungen</a>
                        </div>
                </div>
    }
}

export default TwoFA