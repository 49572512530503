import React, {useEffect} from 'react';

import DataGrid, { Export, Scrolling, Pager, Paging, Column, SearchPanel} from 'devextreme-react/data-grid';
import { exportDataGrid as exportPDF } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportEXCEL} from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';
import { Workbook } from 'exceljs';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import { jsPDF } from 'jspdf';
import _translate from '../Globals/translation';
import { Tooltip } from 'devextreme-react';
import { on } from 'devextreme/events';

interface StatisticsParameters {
    data: Array<InformationList>;
    loading: boolean;
}

export interface InformationList {
    id: number;
    state: String;
    xcode: String;
    ip: String;
    phone: String;
    created: String;
    sent: String;
}     

const StatisticsTable:React.FC<StatisticsParameters> = ({data, loading}) =>{   
    const dataGridRef = React.createRef<DataGrid>();

    useEffect(() => {
        loading ? dataGridRef.current?.instance.beginCustomLoading(_translate("Loading Data...").toString()) : dataGridRef.current?.instance.endCustomLoading();
    }, [loading, dataGridRef]);

    const onExporting = (e: ExportingEvent<InformationList, any>) => {
        if(e.format === 'xlsx'){
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('Send');
            exportEXCEL({
                component: e.component,
                worksheet,
                autoFilterEnabled: true,
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Statistic.xlsx');
                });
            });
            e.cancel = true;   
        } else if (e.format === 'pdf'){
            const doc = new jsPDF('landscape');
            exportPDF({
                jsPDFDocument: doc,
                component: e.component,
                indent: 5,
            }).then(() => {
                doc.save('Statistic.pdf');
            });
        }
    };
    const myTooltip = React.createRef<Tooltip>();

    const onCellPrepared = (e: any) => {
        if (e.rowType === "data" && e.column.dataField === "phone") {
            on(e.cellElement, "mouseover", (arg: any) => {
                myTooltip.current?.instance.show(arg.target);
            });
            on(e.cellElement, "mouseout", (arg: any) => {
                myTooltip.current?.instance.hide();
            });
        }
    };

    return (
        <div>
        <DataGrid
            ref={dataGridRef}
            id='gridContainer'
            dataSource={data}
            //keyExpr={'xcode'}
            keyExpr="id"
            showBorders={true}
            onExporting={e => onExporting(e)}
            columnAutoWidth={true}
            loadPanel={{enabled: true}}
            //change language of the grid
            rtlEnabled={false}
            onCellPrepared={onCellPrepared}
            columnHidingEnabled={true}
        >
            {/* <Column caption={_translate("ID")} dataField="id"></Column> */}
            <Column caption={_translate("State")} dataField="state"></Column>
            <Column caption={_translate("x-code")} dataField="xcode"></Column>
            <Column caption={_translate("IP")} dataField="ip"><div title='sfsdfsdfsdfsdfsdfsf'></div></Column>
            <Column caption={_translate("phone")} dataField="phone"></Column>
            <Column caption={_translate("created")} dataField="created" dataType="datetime" format="dd.MM.yy HH:mm"></Column>
            <Column caption={_translate("sent")} dataField="sent" dataType="datetime" format="dd.MM.yy HH:mm" ></Column>
            <Scrolling rowRenderingMode='virtual'></Scrolling>
            <Paging defaultPageSize={10} />
            <Export enabled={true} formats={['pdf', 'xlsx']}  />
            <Pager
                visible={true}
                allowedPageSizes={true}
                displayMode={'full'}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true} />
            <SearchPanel visible={true} width={240} placeholder={_translate("Search...")} />
        </DataGrid>
        <Tooltip ref={myTooltip} position={'right'}>
            <div className={'tooltipContent'}>
                <div className='tooltiptext'>{_translate("Due to data protection, the phone number is not fully displayed.")}</div>
            </div>
        </Tooltip>
    </div>);
}
export default StatisticsTable;