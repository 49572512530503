import React, { useEffect, useState} from "react";
import _translate from "../Globals/translation";
import StatisticsTable, { InformationList } from "./StatisticsTable";
import { API_URL } from "../Globals/globalVariables";

interface StatisticsParameters {
    sessionTokenAuth: String;
    username: String;
    userid: String;
}

const SendStatistic:React.FC<StatisticsParameters> = ({sessionTokenAuth, userid, username}) => {
    useEffect(() => {
        // set Title of the Page for SEO reasons
        document.title = "Berlin SMS - "  + _translate("Send Statistic");
      }, []);

    const [data, setData] = useState<Array<InformationList>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    // const [moreAvailable, setMoreAvailable] = useState<Boolean>(false);
    
    // getting the user data
    const getStatistic = async () => {
        // count the time the request takes

        setLoading(true);
        try {
            let response = await fetch(API_URL+'/sent/sms?start=0&count=0', {method:'GET', 
                headers: {'Authorization': 'Bearer ' + sessionTokenAuth.toString()}});
            if(!response.ok){
                setLoading(false);
                alert(_translate("Sorry something went wrong. The site was unable to request the current Statistic."));
            } else {
                let answer = await response.json();
                // print time the request took
    
                // check if there ist data
                if(answer.list.length !== 0){
                    // filter the data to remove empty objects
                    var _data = answer.list.filter((e: any) => {
                        if(e === undefined || e === null) return false;
                        if(Object.keys(e).length !== 0) return true; else return false;
                    });
                    // convert the created and sent time to a readable format
                    _data.forEach((item: InformationList, index: number) => {
                        item.id = index;
                    });
                    setData(_data);
                }
            }
        } catch (error) {
            setLoading(false);
            alert(_translate("Sorry something went wrong. The site was unable to request the current Statistic."));
        }
        
        setLoading(false);
    }

    // call once to get initial values
    useEffect(() => {
        getStatistic();
    }, []);

    return <div className='send-statistic'>
        {/* <h1 className="header1 header-sendstatistic">{_translate("Send Statistic")}</h1> */}
        {/* <StatisticsTable data={data}></StatisticsTable> */}
        <StatisticsTable data={data} loading={loading}></StatisticsTable>
    </div>
}

export default SendStatistic
